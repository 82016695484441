<template>
  <div class="home">
    <div class="steps">
      <van-steps :active="active" active-icon="edit" active-color="#E24709">
        <van-step>发起理赔</van-step>
        <van-step>在线审核</van-step>
        <van-step>赔款给付</van-step>
      </van-steps>
    </div>
    <div class="form">
      <van-form>
        <van-field
          v-if="detail.claims"
          label="理赔类型"
          :value="detail.claims.claimsType"
          readonly
        />
        <van-field
          v-if="detail.policy"
          label="被保险人"
          :value="`${detail.policy.contactsName} - ${detail.policy.contactsCard}`"
          readonly
        />
        <van-field
          v-if="detail.policy"
          label="手机号码"
          :value="`${detail.policy.contactsMobile}`"
          readonly
        />
        <van-field
          v-if="detail.policy"
          label="收款账号"
          :value="`${detail.policy.bankName} - ${detail.policy.bankCard}`"
          readonly
        />
      </van-form>
    </div>
    <div class="formImg">
      <p>理赔凭证</p>
      <div class="imgList" v-if="detail.claims">
        <img
          :src="item"
          v-for="(item, index) in imgs"
          :key="index"
          alt=""
        />
      </div>
    </div>
    <div class="stepS">
      <div class="title" v-if="detail.claims">
        <p>理赔单号</p>
        <p>{{ detail.claims.claimsId }}</p>
      </div>
      <van-steps
        active-icon="circle"
        direction="vertical"
        :active="0"
        active-color="#E24709"
      >
        <van-step v-for="(item, index) in detail.logList" :key="index">
          <h3>{{ item.optionValue }}</h3>
          <p>{{ item.optionTime }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { claimsDetail } from "../utils/api";
export default {
  data() {
    return {
      active: 1,
      detail: {},
      imgs: [],
    };
  },
  created() {},
  methods: {
    //理赔单详情
    async claimsDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await claimsDetail(this.$route.query.claimsId).then((res) => {
        if(res.data.claims.claimsCredentials){
          this.imgs = res.data.claims.claimsCredentials.split(",");
        }
        
        this.detail = res.data;
      });
    },
  },
  async mounted() {
    await this.claimsDetail();
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  background: #f2f2f2;
  .steps {
    width: 100%;
    padding: 10px 10px;
    background: #fff;
  }
  .form {
    background: #fff;
    margin-top: 20px;
    width: 100%;
  }
  .formImg {
    background: #fff;
    margin-top: 20px;
    width: 100%;
    padding: 15px;
    p {
      font-size: 14px;
      line-height: 14px;
      color: #646566;
    }
    .imgList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 30%;
        // height: 100px;
        background: #eaeaea;
        margin-right: 5%;
        margin-top: 20px;
      }
      img:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .stepS {
    background: #fff;
    margin-top: 20px;
    width: 100%;
    padding: 5px;
    .title {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        line-height: 14px;
        color: #333;
      }
      p:first-child {
        margin-right: 50px;
      }
    }
  }
}
</style>